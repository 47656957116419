<template>
  <div>
    <b-card no-body class="card-custom" header-tag="header" footer-tag="footer">
      <template v-slot:header>
        <div class="card-title">
          <h3 class="card-label">
            {{ $t("MENU.ITEM.ACTIVITIES.ASSIGNMENTS") }}
            <i class="mr-2"></i>
            <small class="">{{ $t("LABELS.LIST") }}</small>
          </h3>
        </div>
        <div class="card-toolbar">
          <button
            class="btn btn-warning font-weight-bolder ml-2"
            @click="handleTestErrors"
          >
            {{ $t("BUTTON.TEST_ERRORS") }}
          </button>
          <button
            class="btn btn-primary font-weight-bolder ml-2"
            @click="handlePdf"
          >
            {{ $t("BUTTON.PDFPRINT") }}
          </button>
          <button
            class="btn btn-secondary font-weight-bolder ml-2"
            @click="handleExcel"
          >
            {{ $t("BUTTON.EXCEL") }}
          </button>
          <button
            class="btn btn-success font-weight-bolder ml-2"
            @click="handleSend"
          >
            {{ $t("BUTTON.SEND") }}
          </button>
        </div>
      </template>
      <img :src="output" />
      <v-card v-show="!isPageLoading">
        <SearchTools
          :searchQuery.sync="searchQuery"
          @do-search="doSearch"
          @do-clear="doClear"
        >
          <template v-slot:prepend>
            <b-col class="pb-0" :md="6">
              <b-form-group id="assignors-group" label-for="assignors-input">
                <FormSelect
                  id="assignors-input"
                  :placeholder="$t('FORM_LABELS.ASSIGNOR')"
                  type="select"
                  name="assignors"
                  :options="assignors"
                  clearable="true"
                  v-model="filters.assignorId"
                  @input="fetchAssignorLocations"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="6">
              <b-form-group id="locations-group" label-for="locations-input">
                <FormSelect
                  id="locations-input"
                  type="select"
                  :placeholder="$t('FORM_LABELS.LOCATION')"
                  name="locations"
                  :options="locations"
                  clearable="true"
                  v-model="filters.locationId"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="6">
              <b-form-group
                id="machine-types-group"
                label-for="machine-types-input"
              >
                <FormSelect
                  id="machine-types-input"
                  type="select"
                  :placeholder="$t('FORM_LABELS.MACHINE_TYPE')"
                  name="machine-types"
                  :options="machineTypes"
                  clearable="true"
                  v-model="filters.machineTypeId"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="pb-0" :md="6">
              <b-form-group
                id="has-assignments-group"
                label-for="has-assignments-input"
              >
                <FormSelect
                  id="has-assignment-filter-input"
                  type="select"
                  :placeholder="$t('FORM_LABELS.HAS_ASSIGNMENTS_FILTER')"
                  name="has-assignment-filter"
                  :options="[
                    { name: 'Само с ангажименти', id: 1 },
                    { name: 'Само без ангажименти', id: 0 }
                  ]"
                  clearable="true"
                  v-model="filters.hasAssignments"
                ></FormSelect>
              </b-form-group>
            </b-col>
            <b-col class="py-0" :md="6">
              <b-form-group>
                <FormSelect
                  class="year-filter"
                  name="year"
                  :options="yearOptions"
                  v-model="filters.year"
                  @input="getDataFromApi"
                ></FormSelect>
                <v-btn icon @click="handleClickWeekPrev"
                  ><v-icon>mdi-chevron-left</v-icon></v-btn
                >
                <FormInput
                  class="week-filter"
                  type="number"
                  name="week"
                  v-model="filters.week"
                  @keyup.enter="doSearch"
                ></FormInput>
                <v-btn icon @click="handleClickWeekNext"
                  ><v-icon>mdi-chevron-right</v-icon></v-btn
                >
                <span>{{ month }}</span>
                <span>
                  <v-btn class="ml-2" icon @click="getDataFromApi"
                    ><v-icon>mdi-reload</v-icon></v-btn
                  ></span
                >
              </b-form-group>
            </b-col>
          </template>
        </SearchTools>
        <v-data-table
          :key="dataTableKey"
          :headers="headers"
          :items="items"
          :options.sync="options"
          :loading="isLoadingAssignment"
          loading-text="Loading... Please wait"
          hide-default-footer
          disable-pagination
          class="px-6 elevation-1"
        >
          <template v-slot:[`item.machineName`]="{ item }">
            <AssignmentTableMachine
              :machine="item.machine"
              :machine-name="item.machineName"
              :week="filters.week"
              :year="filters.year"
              :viber-user-id="item.viberUserId"
              :assignment-notification-status="
                item.assignmentNotificationStatus
              "
              :has-assignments="item.hasAssignments"
              @update:notification-status="
                handleUpdateNotificationStatus(item, $event)
              "
            />
          </template>
          <template v-slot:[`item.mon`]="{ item }">
            <AssignmentTableItem
              :items="item.mon"
              :date="dateRange.mon"
              :machine="item.machine"
              :week="filters.week"
              :year="filters.year"
              @add="handleItemAdd($event)"
              @delete="handleItemDelete('mon', $event)"
              @update="handleItemUpdate('mon', $event)"
              @update:notification-status="
                handleUpdateNotificationStatus(item, $event)
              "
            />
          </template>
          <template v-slot:[`item.tue`]="{ item }">
            <AssignmentTableItem
              :items="item.tue"
              :date="dateRange.tue"
              :machine="item.machine"
              :week="filters.week"
              :year="filters.year"
              @add="handleItemAdd($event)"
              @delete="handleItemDelete('tue', $event)"
              @update="handleItemUpdate('tue', $event)"
              @update:notification-status="
                handleUpdateNotificationStatus(item, $event)
              "
            />
          </template>
          <template v-slot:[`item.wed`]="{ item }">
            <AssignmentTableItem
              :items="item.wed"
              :date="dateRange.wed"
              :machine="item.machine"
              :week="filters.week"
              :year="filters.year"
              @add="handleItemAdd($event)"
              @delete="handleItemDelete('wed', $event)"
              @update="handleItemUpdate('wed', $event)"
              @update:notification-status="
                handleUpdateNotificationStatus(item, $event)
              "
            />
          </template>
          <template v-slot:[`item.thu`]="{ item }">
            <AssignmentTableItem
              :items="item.thu"
              :date="dateRange.thu"
              :machine="item.machine"
              :week="filters.week"
              :year="filters.year"
              @add="handleItemAdd($event)"
              @delete="handleItemDelete('thu', $event)"
              @update="handleItemUpdate('thu', $event)"
              @update:notification-status="
                handleUpdateNotificationStatus(item, $event)
              "
            />
          </template>
          <template v-slot:[`item.fri`]="{ item }">
            <AssignmentTableItem
              :items="item.fri"
              :date="dateRange.fri"
              :machine="item.machine"
              :week="filters.week"
              :year="filters.year"
              @add="handleItemAdd($event)"
              @delete="handleItemDelete('fri', $event)"
              @update="handleItemUpdate('fri', $event)"
              @update:notification-status="
                handleUpdateNotificationStatus(item, $event)
              "
            />
          </template>
          <template v-slot:[`item.sat`]="{ item }">
            <AssignmentTableItem
              :items="item.sat"
              :date="dateRange.sat"
              :machine="item.machine"
              :week="filters.week"
              :year="filters.year"
              @add="handleItemAdd($event)"
              @delete="handleItemDelete('sat', $event)"
              @update="handleItemUpdate('sat', $event)"
              @update:notification-status="
                handleUpdateNotificationStatus(item, $event)
              "
            />
          </template>
          <template v-slot:[`item.sun`]="{ item }">
            <AssignmentTableItem
              :items="item.sun"
              :date="dateRange.sun"
              :machine="item.machine"
              :week="filters.week"
              :year="filters.year"
              @add="handleItemAdd($event)"
              @delete="handleItemDelete('sun', $event)"
              @update="handleItemUpdate('sun', $event)"
              @update:notification-status="
                handleUpdateNotificationStatus(item, $event)
              "
            />
          </template>

          <template v-slot:no-results>
            <v-alert :value="true" color="error" icon="warning">
              Your search for "{{ searchQuery }}" found no results.
            </v-alert>
          </template>
        </v-data-table>
      </v-card>

      <v-skeleton-loader
        v-if="isPageLoading"
        :loading="isPageLoading"
        type="table"
      ></v-skeleton-loader>
    </b-card>
    <AssignmentSendModal ref="AssignmentSendModal" @send="getDataFromApi" />
  </div>
</template>

<script>
//General

import orderBy from "lodash/orderBy";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import {
  FETCH_ASSIGNMENTS,
  GENERATE_EXCEL_URL
} from "@/modules/assignments/store/assignment.module";
import { FETCH_MACHINES_TYPES } from "@/modules/machines-types/store/machinetype.module";
import { FETCH_ASSIGNORS } from "@/modules/assignors/store/assignors.module";
import { FETCH_ASSIGNOR_LOCATIONS } from "@/modules/assignorslocation/store/location.module";
import { SEND_ASSIGNMENT_NOTIFICATIONS } from "@/modules/assignments/store/assignment-notification.module";

import { permissionMixin } from "@/core/mixins/permissionMixin.js";

import SearchTools from "@/core/components/table/SearchTools.vue";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormInput from "@/view/content/forms/components/FormInput.vue";
import AssignmentTableItem from "../components/AssignmentTableItem.vue";
import AssignmentTableMachine from "../components/AssignmentTableMachine.vue";
import AssignmentSendModal from "../components/AssignmentSendModal.vue";

export default {
  mixins: [permissionMixin],
  components: {
    SearchTools,
    AssignmentTableItem,
    AssignmentTableMachine,
    FormSelect,
    FormInput,
    AssignmentSendModal
  },
  data() {
    return {
      isPageLoading: true,
      tableLoading: true,
      items: [],
      totalItems: 100,
      searchQuery: "",
      filters: {
        year: null,
        week: null,
        machineTypeId: null,
        assignorId: null,
        locationId: null,
        hasAssignments: null
      },
      yearOptions: [],
      columns: {},
      cancelSource: null,
      month: null,
      dateRange: {},
      headers: [],
      dataTableKey: 0,
      output: null
    };
  },
  watch: {},
  mounted() {
    let vm = this;
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.ACTIVITIES.ASSIGNMENTS"),
        route: { name: "list-assignment" }
      },
      { title: this.$i18n.t("MENU.ITEM.ASSETS.LIST") }
    ]);
    this.fetchMachineTypes();
    this.fetchAssignors();
    this.fetchAssignorLocations();
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["isLoadingAssignment"]),
    params() {
      return {
        ...this.options,
        ...this.filters,
        query: this.searchQuery
      };
    }
  },
  methods: {
    setHeaders() {
      this.headers = [
        {
          text: this.$i18n.t("ASSIGNMENTS.MACHINE"),
          value: "machineName",
          divider: true,
          cellClass: "machine-cell",
          width: 150
        }
      ];
      Object.keys(this.$i18n.t("ASSIGNMENTS.DAYS")).forEach(d => {
        this.headers.push({
          text: this.$i18n.t("ASSIGNMENTS.DAYS")[d],
          value: d,
          searchable: false,
          width: 100,
          align: "center",
          divider: true,
          cellClass: "calendar-cell"
        });
      });
    },
    cancelRequest() {
      //Axios cancelSource to stop current search if new value is entered
      if (this.cancelSource) {
        this.cancelSource.cancel("Start new search, stop active search");
      }
    },
    doSearch() {
      let vm = this;
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    doClear() {
      let vm = this;
      vm.searchQuery = "";
      if (vm.options.page == 1) {
        vm.getDataFromApi();
      } else {
        vm.options.page = 1;
      }
    },
    getDataFromApi() {
      if (this.isLoadingAssignment) {
        return;
      }
      let vm = this;
      // vm.tableLoading = true;
      //copy current params to modify
      let params = this.params;

      params.length = params.itemsPerPage; //set how many records to fecth per page
      params.start =
        params.page == 1 ? 0 : params.itemsPerPage * (params.page - 1); //set offset
      // console.log(this.params);
      let apiParams = this.$url.transformParams(params);
      // console.log(apiParams);
      vm.$store
        .dispatch(FETCH_ASSIGNMENTS, apiParams)
        .then(data => {
          vm.$nextTick(function() {
            vm.dateRange = data.data.dateRange;
            vm.setDaysRange(data.data.daysRange);
            vm.items = data.data.items;
            vm.totalItems = data.data.totalItemsCount;
            vm.month = data.data.month;
            vm.filters.year = data.data.year;
            vm.filters.week = data.data.week;
            vm.yearOptions = data.data.yearOptions;
            if (vm.isPageLoading == true) {
              vm.isPageLoading = false;
            }
            vm.dataTableKey++;
          });
        })
        .catch(response => {
          if (response.status === 404) {
            vm.$notify({
              group: "notify",
              type: "error",
              text: "Nout Found"
            });
            this.$router.push({ name: "list-assignment" });
          }
        });
    },
    setDaysRange(range) {
      this.setHeaders();
      range.forEach((day, index) => {
        this.headers[index + 1].text = this.headers[index + 1].text + " " + day;
      });
    },
    handleClickWeekPrev() {
      this.filters.week--;
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.getDataFromApi();
      }, 200);
    },
    handleClickWeekNext() {
      this.filters.week++;
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.getDataFromApi();
      }, 200);
    },
    findItem(item) {
      return this.items.find(
        i =>
          i.machine.machinableId === item.machine.machinableId &&
          i.machine.machinableType == item.machine.machinableType
      );
    },
    handleItemAdd(items) {
      items.forEach(item => {
        let assignmentDay = null;
        Object.keys(this.dateRange).forEach(day => {
          if (this.dateRange[day] === item.date) {
            assignmentDay = day;
          }
        });
        if (assignmentDay) {
          this.addItem(assignmentDay, item);
        }
      });
    },
    addItem(day, item) {
      let row = this.findItem(item);
      if (row) {
        row[day] = row[day] ? [...row[day], item] : [item];
      }
    },
    handleItemDelete(day, item) {
      let row = this.findItem(item);
      if (row[day].length === 1) {
        row[day] = null;
      } else {
        const itemIndex = row[day].findIndex(r => r.id === item.id);
        row[day].splice(itemIndex, 1);
      }
    },
    handleItemUpdate(day, item) {
      let row = this.findItem(item);

      if (row[day].length === 1) {
        row[day] = null;
        row[day] = [item];
      } else {
        let itemIndex = row[day].findIndex(r => r.id === item.id);
        row[day].splice(itemIndex, 1, item);
        row[day] = orderBy(row[day], "startTime");
      }
    },
    fetchMachineTypes() {
      this.$store
        .dispatch(
          FETCH_MACHINES_TYPES,
          this.$url.transformParams({ onlySelectValues: true })
        )
        .then(data => {
          this.machineTypes = data.data.items;
        });
    },
    fetchAssignors() {
      this.$store
        .dispatch(
          FETCH_ASSIGNORS,
          this.$url.transformParams({
            onlySelectValues: true,
            selectValueKey: "company_name"
          })
        )
        .then(data => {
          this.assignors = data.data.items;
        });
    },
    fetchAssignorLocations(id = null) {
      this.$store
        .dispatch(
          FETCH_ASSIGNOR_LOCATIONS,
          this.$url.transformParams({
            assignorId: id
          })
        )
        .then(data => {
          this.locations = data.data.items;
          if (this.locations.length === 1) {
            this.filters.locationId = this.locations[0].id;
          } else {
            this.filters.locationId = null;
          }
        });
    },
    handleExcel() {
      const apiParams = this.$url.transformParams({
        dateFrom: this.dateRange.mon,
        dateTo: this.dateRange.sun
      });
      this.$store.dispatch(GENERATE_EXCEL_URL, apiParams);
    },
    handlePdf() {
      window.print();
    },
    handleUpdateNotificationStatus(item, status) {
      let row = this.findItem(item);
      row.assignmentNotificationStatus = status;
      // Set all assignments for that machine to "green"
      if (status === 0) {
        Object.keys(this.$i18n.t("ASSIGNMENTS.DAYS")).forEach(d => {
          if (!item[d]) {
            return;
          }
          item[d].forEach(a => {
            a.isConfirmed = true;
          });
        });
      }
    },
    handleTestErrors() {
      this.$store
        .dispatch(SEND_ASSIGNMENT_NOTIFICATIONS, {
          week: this.filters.week,
          year: this.filters.year,
          errorTest: true
        })
        .finally(() => {
          this.getDataFromApi();
        });
    },
    handleSend() {
      this.$refs.AssignmentSendModal.show();
    }
  }
};
</script>

<style lang="sass" scoped>
.year-filter
  display: inline-block
  width: 90px
.week-filter
  display: inline-flex
  width: 60px
::v-deep
  .machine-cell
    max-width: 150px
    position: relative
    font-size: 11px !important
  .calendar-cell
    max-width: 100px
    position: relative
    padding: 8px !important
</style>
