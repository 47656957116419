<template>
  <div>
    <b-form-group
      id="item-type"
      v-bind:label="$t('ASSIGNMENTS.TYPE')"
      label-for="item-type"
    >
      <FormSelect
        id="item-type"
        type="select"
        name="itemTypes"
        :options="types"
        v-model="form.type"
      ></FormSelect>
    </b-form-group>
    <b-form-group
      v-if="!edit"
      id="item-vehicle-group"
      :label="$t('FORM_PLACEHOLDERS.DATES')"
      label-for="item-reg-plate-input"
    >
      <FormSelect
        id="item-date-input"
        type="select"
        clearable
        name="itemDate"
        multiple
        :options="dates"
        v-model="form.date"
        @input="checkAreAvailable"
      ></FormSelect>
    </b-form-group>
    <b-form-group
      v-if="!edit"
      id="item-vehicle-group"
      :label="$t('FORM_PLACEHOLDERS.MACHINES')"
      label-for="item-reg-plate-input"
    >
      <FormSelect
        v-if="machines.length > 0"
        id="item-vehicle-number-input"
        type="select"
        clearable
        name="itemMachine"
        multiple
        :options="machines"
        v-model="form.machine"
        @input="checkAreAvailable"
      ></FormSelect>
    </b-form-group>
    <div class="mb-2" v-show="_.size(areAvailableErrors) > 0">
      <div class="alert-text" v-for="(error, i) in areAvailableErrors" :key="i">
        <span
          :class="`text-${error.includes('частичен') ? 'warning' : 'danger'}`"
          >{{ error }}</span
        >
      </div>
    </div>
    <b-form-group
      v-if="form.type === 'assignment'"
      id="item-assignors-group"
      v-bind:label="$t('MENU.ITEM.ASL.ASSIGNOR')"
      label-for="item-assignor-input"
    >
      <FormSelect
        id="item-assignors-input"
        type="select"
        clearable
        name="itemAssignors"
        :options="assignors"
        v-model="form.assignorId"
        @input="fetchAssignorLocations"
      ></FormSelect>
    </b-form-group>
    <b-form-group
      v-if="form.type === 'assignment'"
      id="item-locations-group"
      v-bind:label="$t('MENU.ITEM.ASL.LOCATION')"
      label-for="item-locations-input"
    >
      <FormSelect
        id="item-locations-input"
        type="select"
        clearable
        name="itemLocations"
        :options="locations"
        v-model="form.locationId"
      ></FormSelect>
    </b-form-group>
    <b-form-group
      :label="$t('ASSIGNMENTS.START_TIME')"
      label-for="item-start-time"
    >
      <FormTimepicker
        id="item-start-time"
        name="start_time"
        v-model="form.startTime"
      ></FormTimepicker>
    </b-form-group>
    <b-form-group :label="$t('ASSIGNMENTS.END_TIME')" label-for="item-end-time">
      <FormTimepicker
        id="item-end-time"
        name="end_time"
        v-model="form.endTime"
      ></FormTimepicker>
    </b-form-group>
    <b-form-group
      :label="$t('ASSIGNMENTS.DESCRIPTION')"
      label-for="item-description"
    >
      <FormTextarea
        id="item-description"
        name="description"
        :i18n="{
          placeholder: 'FORM_PLACEHOLDERS.DESCRIPTION_MAX_140'
        }"
        v-model="form.description"
      ></FormTextarea>
    </b-form-group>
    <b-form-group
      :label="$t('ASSIGNMENTS.IS_FULL_DAY')"
      label-for="item-is-full-day"
    >
      <FormSwitch
        id="item-is-full-day"
        type="checkbox"
        name="is_full_day"
        v-model="form.isFullDay"
      ></FormSwitch>
    </b-form-group>
    <AssignmentTableItemLog :log="log" />
  </div>
</template>

<script>
import moment from "moment";

import { FETCH_MACHINES } from "@/modules/machines/store/machine.module";
import { FETCH_RENTED_MACHINES } from "@/modules/rented-machines/store/rentedmachine.module";
import { FETCH_ASSIGNOR_LOCATIONS } from "@/modules/assignorslocation/store/location.module";
import { FETCH_ASSIGNORS } from "@/modules/assignors/store/assignors.module";
import {
  CHECK_ARE_AVAILABLE,
  FETCH_LOG
} from "@/modules/assignments/store/assignment.module";

import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import FormSwitch from "@/view/content/forms/components/FormSwitch.vue";
import FormTimepicker from "@/view/content/forms/components/FormTimepicker.vue";
import FormTextarea from "@/view/content/forms/components/FormTextarea.vue";
import AssignmentTableItemLog from "./AssignmentTableItemLog";

export default {
  components: {
    FormSelect,
    FormSwitch,
    FormTimepicker,
    FormTextarea,
    AssignmentTableItemLog
  },
  props: {
    form: Object,
    edit: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      machines: [],
      assignors: [],
      locations: [],
      dates: [],
      types: [
        {
          id: "assignment",
          name: "Ангажимент"
        },
        {
          id: "repair",
          name: "Ремонт"
        },
        {
          id: "rest",
          name: "Почивка"
        }
      ],
      areAvailableErrors: {},
      log: null
    };
  },
  mounted() {
    if (!this.edit) {
      this.fetchMachines();
      this.setDates();
    }

    this.fetchAssignors();
    this.fetchLog();
  },
  methods: {
    checkAreAvailable() {
      this.$store
        .dispatch(CHECK_ARE_AVAILABLE, {
          date: this.form.date,
          machine: this.form.machine,
          isFullDay: this.form.isFullDay
        })
        .then(() => {
          this.areAvailableErrors = {};
        })
        .catch(response => {
          this.areAvailableErrors = this.$errors.format(
            response.data.errors || {}
          );
        });
    },
    fetchLog() {
      const machine = this.edit ? this.form.machine : this.form.machine[0];
      const date = this.edit ? this.form.date : this.form.date[0];

      this.$store
        .dispatch(FETCH_LOG, {
          date: date,
          machinableId: machine.machinableId,
          machinableType: machine.machinableType
        })
        .then(data => {
          this.log = data.data;
        });
    },
    fetchMachines() {
      this.$store.dispatch(FETCH_MACHINES, "?isAssignable=1").then(data => {
        let items = data.data.items;
        let machines = [];

        if (items && items.length > 0) {
          for (let i = 0; i < items.length; i++) {
            machines.push({
              id: {
                machinableId: items[i].id,
                machinableType: items[i].expenseType
              },
              name:
                items[i].name +
                " - " +
                "Собствена" +
                " | " +
                items[i].machineType
            });
          }
        }
        this.machines = machines;

        this.$store
          .dispatch(FETCH_RENTED_MACHINES, "?isAssignable=1")
          .then(data => {
            let items = data.data.items;
            if (items && items.length > 0) {
              for (let i = 0; i < items.length; i++) {
                this.machines.push({
                  id: {
                    machinableId: items[i].id,
                    machinableType: items[i].expenseType
                  },
                  name:
                    items[i].name +
                    " - " +
                    items[i].subcontractorName +
                    " | " +
                    items[i].machineType
                });
              }
            }
          });
      });
    },
    fetchAssignors() {
      this.$store
        .dispatch(
          FETCH_ASSIGNORS,
          this.$url.transformParams({
            onlySelectValues: true,
            selectValueKey: "company_name"
          })
        )
        .then(data => {
          this.assignors = data.data.items;
          if (this.form.assignorId) {
            this.fetchAssignorLocations(this.form.assignorId);
          }
        });
    },
    fetchAssignorLocations(id) {
      this.locations = [];
      this.$store
        .dispatch(
          FETCH_ASSIGNOR_LOCATIONS,
          this.$url.transformParams({
            assignorId: id
          })
        )
        .then(data => {
          this.locations = data.data.items || [];
          this.form.locationId = this.locations.find(
            l => l.id === this.form.locationId
          )
            ? this.form.locationId
            : null;

          if (this.locations.length === 1) {
            this.form.locationId = this.locations[0].id;
          }
        });
    },
    setDates() {
      const startDate = moment(this.form.date[0]).subtract(15, "days");
      const endDate = moment(this.form.date[0]).add(15, "days");

      this.dates = this.enumerateDaysBetweenDates(startDate, endDate);
    },
    enumerateDaysBetweenDates(startDate, endDate) {
      var dates = [];

      var currDate = startDate.startOf("day");
      var lastDate = endDate.startOf("day");

      while (currDate.add(1, "days").diff(lastDate) < 0) {
        dates.push({
          id: currDate.clone().format("YYYY-MM-DD"),
          name: currDate.clone().format("DD-MM-YYYY")
        });
      }

      return dates;
    }
  }
};
</script>
