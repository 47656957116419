<template>
  <v-dialog v-model="dialog" max-width="600px">
    <v-card>
      <v-card-title
        class="white--text"
        :class="form.type === 'repair' ? 'warning' : 'primary'"
      >
        <span class="assignment-item-title text-h5">
          Добави ремонт/ангажимент
        </span>
      </v-card-title>
      <v-card-text>
        <v-container class="assignment-form-container">
          <AssignmentTableItemForm :key="formKey" :form="form" />
          <div
            v-show="_.size(errors) > 0"
            role="alert"
            class="alert alert-danger"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ Array.isArray(error) ? error[0] : error }}
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="dialog = false">
          Затвори
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleSave">
          Запази
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { SAVE_ASSIGNMENT } from "@/modules/assignments/store/assignment.module";

import AssignmentTableItemForm from "./AssignmentTableItemForm";

export default {
  components: {
    AssignmentTableItemForm
  },
  props: {
    date: String,
    machine: Object
  },
  data() {
    return {
      dialog: false,
      form: {
        assignorId: null,
        locationId: null,
        type: "assignment",
        machine: null,
        date: null,
        startTime: "08:00",
        endTime: null,
        isFullDay: true,
        description: null
      },
      errors: {},
      formKey: 0
    };
  },
  computed: {},
  mounted() {
    this.form.date = [this.date];
    this.form.machine = [this.machine];
  },
  methods: {
    show() {
      this.dialog = true;
      this.resetForm();
      this.formKey++;
    },
    closeModal() {
      this.dialog = false;
    },
    handleSave() {
      this.errors = {};
      this.$store
        .dispatch(SAVE_ASSIGNMENT, this.form)
        .then(data => {
          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });
          this.closeModal();
          this.$emit("add", data.data);
        })
        .catch(response => {
          this.errors = this.$errors.format(response.data.errors || {});

          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    },
    resetForm() {
      this.form = {
        assignorId: null,
        locationId: null,
        type: "assignment",
        machine: null,
        date: null,
        startTime: "08:00",
        endTime: null,
        isFullDay: true,
        description: null
      };
      this.form.date = [this.date];
      this.form.machine = [this.machine];
    }
  }
};
</script>

<style lang="sass" scoped>
.assignment-form-container
  min-height: 400px
.assignment-item-title
  flex: 1
  word-break: normal
</style>
