<template>
  <v-dialog
    class="send-modal"
    v-model="dialog"
    max-width="600px"
    :persistent="loading"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="white--text primary">
        <span class="assignment-item-title text-h5">
          Изпрати график за ден или седмица
        </span>
      </v-card-title>
      <v-card-text>
        <v-container class="assignment-form-container">
          <b-form-group
            id="year"
            v-bind:label="$t('LABELS.YEAR')"
            label-for="year"
          >
            <FormSelect
              class="year"
              name="year"
              :options="yearOptions"
              v-model="form.year"
              @input="onYearChange"
            ></FormSelect>
          </b-form-group>
          <b-form-group
            id="week"
            v-bind:label="$t('LABELS.WEEK')"
            label-for="week"
          >
            <FormSelect
              class="week"
              name="week"
              :options="weekOptions"
              v-model="form.week"
              @input="onWeekChange"
            ></FormSelect>
          </b-form-group>
          <b-form-group
            id="day"
            v-bind:label="$t('LABELS.DAY')"
            label-for="day"
          >
            <FormSelect
              class="day"
              name="day"
              :options="dayOptions"
              clearable
              v-model="form.day"
            ></FormSelect>
          </b-form-group>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="dialog = false">
          {{ $t("BUTTON.CLOSE") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleClickSend">
          {{ $t("BUTTON.SEND") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
import FormSelect from "@/view/content/forms/components/FormSelect.vue";
import { SEND_ASSIGNMENT_NOTIFICATIONS } from "@/modules/assignments/store/assignment-notification.module";

export default {
  components: {
    FormSelect
  },
  data() {
    return {
      dialog: false,
      loading: false,
      form: {
        year: null,
        week: null,
        day: null
      },
      errors: {},
      yearOptions: [],
      weekOptions: [],
      dayOptions: []
    };
  },
  mounted() {
    const currentYear = moment().year();
    this.yearOptions = [currentYear, currentYear + 1].map(x => ({
      id: x,
      name: x
    }));
    this.form.year = currentYear;
    this.onYearChange(currentYear);
    this.form.week = moment().isoWeek();
    this.onWeekChange(this.form.week);
    this.form.day = moment().weekday();
  },
  methods: {
    show() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    onYearChange(year) {
      this.weekOptions = [...Array(moment(year).isoWeeksInYear()).keys()].map(
        x => ({
          id: x,
          name: x
        })
      );
      this.form.week = this.weekOptions[0];
      this.onWeekChange(this.form.week);
    },
    onWeekChange(week) {
      const startOfWeek = moment()
        .year(this.form.year)
        .isoWeek(week)
        .startOf("isoWeek");
      const endOfWeek = moment()
        .year(this.form.year)
        .isoWeek(week)
        .endOf("isoWeek");

      this.dayOptions = [];
      let day = startOfWeek;

      while (day <= endOfWeek) {
        this.dayOptions.push({
          id: day.weekday(),
          name: day.format("DD dddd")
        });
        day = day.clone().add(1, "d");
      }

      this.form.day = null;
    },
    handleClickSend() {
      this.loading = true;
      this.$store
        .dispatch(SEND_ASSIGNMENT_NOTIFICATIONS, this.form)
        .finally(() => {
          this.$emit("send");
          this.closeModal();
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="sass" scoped>
.assignment-form-container
  min-height: 400px
.assignment-item-title
  flex: 1
  word-break: normal
</style>
