<template>
  <v-expansion-panels v-if="log && log.length > 0" flat v-model="panel">
    <v-expansion-panel>
      <v-expansion-panel-header>
        Лог за деня
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          v-for="l in log"
          :key="l.id"
          :class="`${l.event === 'created' ? 'success' : 'error'}--text`"
        >
          <span>[{{ l.createdAt | formatDateTime }}]</span>
          <span> {{ l.user.firstName }} {{ l.user.lastName }}</span>
          <b> {{ titles[l.event] }}</b>
          <span>{{ l.assignment.isFullDay ? "" : " частичен" }}</span>
          <span> {{ types[l.assignment.type] }}</span>
          <span> за машина </span>
          <span>
            {{ l.machineName }}
          </span>
          <span v-if="l.assignment.type === 'assignment'">
            <span
              >на обект {{ l.locationName }} за възложител
              {{ l.assignorName }}</span
            >
          </span>
          <template v-if="l.updates">
            <div v-for="u in l.updates" :key="u.id" class="ml-2 warning--text">
              <span>[{{ u.createdAt | formatDateTime }}]</span>
              <span> {{ u.user.firstName }} {{ u.user.lastName }}</span>
              <b> направи промяна.</b><br />
              <small> Стари стойности {{ JSON.stringify(u.oldValues) }}.</small
              ><br />
              <small> Нови стойности {{ JSON.stringify(u.newValues) }}</small>
            </div>
          </template>
          <hr />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  props: {
    log: Array
  },
  data() {
    return {
      panel: 0,
      titles: {
        created: "създаде",
        deleted: "изтри"
      },
      types: {
        assignment: "ангажимент",
        repair: "ремонт"
      },
      valuesKey: {
        created: "newValues",
        updated: "oldValues"
      }
    };
  },
  computed: {}
};
</script>

<style lang="sass" scoped>
.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap, .v-expansion-panel-header
  padding: 0 !important
</style>
