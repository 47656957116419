<template>
  <v-chip
    class="assignment-table-item-chip my-1 w-100 justify-center"
    :class="`${backgroundClass}`"
    :title="item.location"
    small
    :color="color"
    :outlined="!item.isFullDay"
    @click="$emit('click', item)"
  >
    <span class="text-truncate w-100">
      <v-icon v-if="item.type === 'repair'" size="12">
        {{ icon }}
      </v-icon>
      {{ item.location }}
    </span>
    <span v-if="item.staffName" class="text-truncate w-100">
      {{ item.staffName }}
    </span>
  </v-chip>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  computed: {
    icon() {
      switch (this.item.type) {
        case "assignment":
          return "mdi-clock";

        case "repair":
          return "mdi-wrench";

        default:
          return "";
      }
    },
    color() {
      switch (this.item.type) {
        case "assignment":
          return "info";

        case "repair":
          return "warning";

        default:
          return "";
      }
    },
    backgroundClass() {
      return this.item.isFullDay
        ? this.item.type === "repair"
          ? "warning--bg"
          : "info--bg"
        : null;
    }
  }
};
</script>

<style lang="sass" scoped>
.assignment-table-item-chip
  font-size: 11px !important
  height: auto !important
.confirmed-border
  border: 2px solid var(--v-success-base) !important

::v-deep
  .v-chip__content
    width: 100%
    flex-flow: column

@media print
  .assignment-table-item-chip
    -webkit-print-color-adjust: exact
</style>
