<template>
  <div @mouseover="handleMouserOver" @mouseout="handleMouserOut">
    <div v-if="items">
      <v-overlay v-if="isSend && !isConfirmed" color="yellow lighten-4" absolute />
      <div class="table-item-chip">
        <AssignmentTableItemChip
          v-for="(item, index) in items"
          :key="index"
          :item="item"
          @click="handleItemClick(item)"
        />
        <v-icon
          v-if="!isFullDay && items.length < 6"
          color="success"
          @click="handleCellClick"
        >
          mdi-plus
        </v-icon>
      </div>
    </div>
    <div v-else class="table-item" @click="handleCellClick">
      <v-overlay :color="cellColor" absolute />
      <v-icon v-if="isHover">
        mdi-plus
      </v-icon>
    </div>
    <AssignmentTableItemAdd
      ref="AssignmentTableItemAdd"
      :date="date"
      :machine="machine"
      @add="handleItemAdd"
    />
    <AssignmentTableItemEdit
      ref="AssignmentTableItemEdit"
      @update="handleItemUpdate"
      @delete="handleItemDelete"
    />
  </div>
</template>

<script>
import { SEND_ASSIGNMENT_NOTIFICATIONS } from "@/modules/assignments/store/assignment-notification.module";

import AssignmentTableItemChip from "./AssignmentTableItemChip";
import AssignmentTableItemAdd from "./AssignmentTableItemAdd";
import AssignmentTableItemEdit from "./AssignmentTableItemEdit";

export default {
  components: {
    AssignmentTableItemChip,
    AssignmentTableItemAdd,
    AssignmentTableItemEdit
  },
  data() {
    return {
      isHover: false,
      mouseOverColor: "red lighten-3",
      mouseDefaultColor: "red lighten-4",
      selectedItem: null
    };
  },
  props: {
    items: {
      type: Array,
      default: null
    },
    date: String,
    week: String,
    year: String,
    machine: Object
  },
  computed: {
    cellColor() {
      return this.isHover ? this.mouseOverColor : this.mouseDefaultColor;
    },
    isFullDay() {
      return this.items ? this.items[0].isFullDay : false;
    },
    isConfirmed() {
      return this.items ? this.items[0].isConfirmed : false;
    },
    isSend() {
      return this.items ? this.items[0].isSend : false;
    }
  },
  methods: {
    handleMouserOver() {
      this.isHover = true;
    },
    handleMouserOut() {
      this.isHover = false;
    },
    handleCellClick() {
      this.$refs.AssignmentTableItemAdd.show();
    },
    handleItemClick(item) {
      this.$refs.AssignmentTableItemEdit.show(item);
    },
    handleItemDelete(item) {
      this.$emit("delete", item);
      if (this.isSend) {
        this.items[0].isSend = false;
        this.showConfirmSendDialog();
      }
    },
    handleItemAdd(item) {
      this.$emit("add", item);
      if (this.isSend) {
        this.items[0].isSend = false;
        this.showConfirmSendDialog();
      }
    },
    handleItemUpdate(item) {
      this.$emit("update", item);
      if (this.isSend) {
        this.items[0].isSend = false;
        this.showConfirmSendDialog();
      }
    },
    resendNotifications() {
      this.loading = true;
      this.$store
        .dispatch(SEND_ASSIGNMENT_NOTIFICATIONS, {
          week: this.week,
          year: this.year,
          machinableId: this.items[0].machine.machinableId,
          machinableType: this.items[0].machine.machinableType
        })
        .then(data => {
          this.$emit("update:notification-status", data);
        });
    },
    showConfirmSendDialog() {
      const message = this.$t("ASSIGNMENTS.CONFIRM_RESEND_NOTIFICATIONS");
      this.$dialog
        .confirm(message)
        .then(() => {
          this.resendNotifications();
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="sass" scoped>
.table-item
  cursor: pointer
.table-item-chip
  z-index: 5
  position: relative

@media print
  .table-item
    -webkit-print-color-adjust: exact
</style>
