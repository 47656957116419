<template>
  <div class="table-machine" @click="handleCellClick">
    <v-overlay :color="cellColor" absolute />
    <div class="table-machine-name">
      {{ machineName }}
    </div>
    <AssignmentTableMachineModal
      ref="AssignmentTableMachineModal"
      :machine-name="machineName"
      :machine="machine"
      :viber-log="viberLog"
      :notifications="notifications"
      :assignment-notification-status="assignmentNotificationStatus"
      :has-assignments="hasAssignments"
      :loading="loading"
      @click:send="handleClickSend"
      @expand:viber="handleExpandViber"
    />
  </div>
</template>

<script>
import {
  FETCH_ASSIGNMENT_NOTIFICATIONS,
  SEND_ASSIGNMENT_NOTIFICATIONS
} from "@/modules/assignments/store/assignment-notification.module";
import { FETCH_VIBER_LOG } from "@/modules/assignments/store/viber-log.module";

import AssignmentTableMachineModal from "./AssignmentTableMachineModal";

export default {
  components: {
    AssignmentTableMachineModal
  },
  data() {
    return {};
  },
  props: {
    machineName: String,
    viberUserId: Number,
    assignmentNotificationStatus: Number,
    week: String,
    year: String,
    machine: Object,
    hasAssignments: Boolean
  },
  data() {
    return {
      dialog: false,
      loading: false,
      notifications: [],
      viberLog: []
    };
  },
  computed: {
    cellColor() {
      if (this.assignmentNotificationStatus === 0) {
        return "green lighten-4";
      }

      if (this.assignmentNotificationStatus > 0) {
        return "red lighten-4";
      }

      return null;
    }
  },
  methods: {
    handleCellClick() {
      this.fetchNotifications();
      this.$refs.AssignmentTableMachineModal.show();
    },
    fetchNotifications(resend = false) {
      this.loading = true;
      this.$store
        .dispatch(
          FETCH_ASSIGNMENT_NOTIFICATIONS,
          this.$url.transformParams({
            week: this.week,
            year: this.year,
            machinableId: this.machine.machinableId,
            machinableType: this.machine.machinableType
          })
        )
        .then(data => {
          this.notifications = data.data;

          if (resend) {
            this.$emit(
              "update:notification-status",
              this.notifications.length ? this.notifications[0].status : -1
            );
          }

          this.loading = false;
        });
    },
    fetchViberLog() {
      if (!this.viberUserId) {
        return;
      }
      this.$store
        .dispatch(
          FETCH_VIBER_LOG,
          this.$url.transformParams({
            viberUserId: this.viberUserId
          })
        )
        .then(data => {
          this.viberLog = data.data;
        });
    },
    handleClickSend() {
      this.loading = true;
      this.$store
        .dispatch(SEND_ASSIGNMENT_NOTIFICATIONS, {
          week: this.week,
          year: this.year,
          machinableId: this.machine.machinableId,
          machinableType: this.machine.machinableType
        })
        .finally(() => {
          this.fetchNotifications(true);
          this.fetchViberLog();
        });
    },
    handleExpandViber() {
      this.fetchViberLog();
    }
  }
};
</script>

<style lang="sass" scoped>
.table-machine
  cursor: pointer
  .table-machine-name
    z-index: 5
    position: relative

@media print
  .table-machine
    -webkit-print-color-adjust: exact
</style>
