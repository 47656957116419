<template>
  <div class="mb-2">
    <b-input-group class="mb-3">
      <b-form-input
        id="example-input"
        :value="value"
        type="text"
        placeholder="HH:mm"
        @input="setValue"
      ></b-form-input>
      <b-input-group-append>
        <b-form-timepicker
          v-bind="$attrs"
          :value="value"
          button-only
          right
          locale="bg"
          aria-controls="example-input"
          @input="setValueFromTimepicker"
        ></b-form-timepicker>
      </b-input-group-append>
    </b-input-group>
    <span v-if="validateState === false" class="text-danger">
      {{ $t("VALIDATION.INVALID", { name: "" }) }}
    </span>
  </div>
</template>

<script>
export default {
  name: "FormTimepicker",
  inheritAttrs: false,
  props: {
    i18n: {
      type: Object,
      default: () => ({})
    },
    /**
     * All validations rules and params
     */
    validations: {
      type: Object,
      default: () => ({})
    },
    /**
     * Validate State validation
     */
    validateState: {
      type: Boolean,
      default: null
    },
    value: {}
  },
  methods: {
    setValue(value) {
      //  trigger a mutation, or dispatch an action
      this.$emit("input", value);
    },
    setValueFromTimepicker(value) {
      if (!value) {
        this.setValue(value);
      }
      const newValue = value.length === 8 ? value.slice(0, -3) : value;

      //  trigger a mutation, or dispatch an action
      this.$emit("input", newValue);
    }
  }
};
</script>
