<template>
  <v-dialog
    class="machine-modal"
    v-model="dialog"
    max-width="600px"
    :persistent="loading"
  >
    <v-card :loading="loading" :disabled="loading">
      <v-card-title class="white--text primary">
        <span class="assignment-item-title text-h5">
          Лог - {{ machineName }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container class="assignment-form-container">
          <div class="top-section mt-5">
            <!-- Case no notifications -->
            <div v-if="assignmentNotificationStatus === -1">
              <span class="text-h6">
                За тази машина не е изпратен седмичен график
              </span>
            </div>
            <!-- Case no staff -->
            <div v-else-if="assignmentNotificationStatus === 1">
              <span class="text-h6 error--text">
                Към тази машина няма служител. Моля влезте в
                <a
                  target="_blank"
                  :href="`/machines/edit/${machine.machinableId}`"
                >
                  машината
                </a>
                и изберете служител, след което го поканете във Viber.
              </span>
            </div>
            <!-- Case no phone own -->
            <div
              v-else-if="
                assignmentNotificationStatus === 2 &&
                  machine.machinableType === 'OwnMachineExpense'
              "
            >
              <span class="text-h6 error--text">
                Към служителя за тази машина няма въведен телефонен номер. Моля
                влезте в
                <a target="_blank" :href="`/staff/edit/${staffId}`">
                  служителя
                </a>
                и добавете телефонен номер.
              </span>
            </div>
            <!-- Case no phone rented -->
            <div
              v-else-if="
                assignmentNotificationStatus === 2 &&
                  machine.machinableType === 'RentedMachineExpense'
              "
            >
              <span class="text-h6 error--text">
                Към служителя за тази наета машина няма въведен телефонен номер.
                Моля влезте в
                <a
                  target="_blank"
                  :href="`/rented-machines/edit/${machine.machinableId}`"
                >
                  машината
                </a>
                и добавете телефонен номер.
              </span>
            </div>
            <!-- Case no viber authentication -->
            <div v-else-if="assignmentNotificationStatus === 3">
              <span class="text-h6 error--text">
                Служителят не се е идентифицирал във Viber.
              </span>
              <br />
              <span class="text-subtitle">
                Моля поканете го в канала на "Lyunik", за да се идентифицира.
              </span>
            </div>
            <!-- Case success -->
            <div v-else-if="assignmentNotificationStatus === 0">
              <span class="text-h6 success--text">
                Служителят е информиран успешно относно седмичния график.
                <span v-if="isConfirmed">
                  Съобщението по Viber е потвърдено.
                </span>
                <span class="text-h6 error--text" v-else
                  ><br />Съобщението по Viber все още не е потвърдено.</span
                >
              </span>
            </div>

            <div v-if="assignmentNotificationStatus > 0">
              <b>
                След като направите необходимите промени/действия, моля
                нитиснете бутона
                {{ $t("BUTTON.SEND_ASSIGNMENT_NOTIFICATIONS") }}.
              </b>
            </div>

            <div v-if="hasAssignments" class="mt-5">
              <button
                class="btn btn-primary font-weight-bolder"
                @click="handleClickSend"
              >
                {{ $t("BUTTON.SEND_ASSIGNMENT_NOTIFICATIONS") }}
              </button>
            </div>
            <div v-else class="mt-5">
              За тази машина няма въведени ангажименти
            </div>
          </div>
          <hr />
          <div v-if="notifications.length" class="notifications-section">
            <span class="text-h6">Лог на изпращане на график</span>
            <div
              v-for="n in notifications"
              :key="n.id"
              class="notifications-section-item"
              :class="`${n.status === 0 ? 'success' : 'error'}--text`"
            >
              <span>[{{ n.createdAt | formatDateTime }}]</span>
              <span>
                {{ n.status === 0 ? "Успешно изпращане на график" : n.error }}
              </span>
              <span>
                {{
                  n.isConfirmed ? "(потвърдено във Viber от служител)" : null
                }}
              </span>
            </div>
            <hr />
          </div>
          <v-expansion-panels flat v-model="panel">
            <v-expansion-panel @change="handleExpand">
              <v-expansion-panel-header>
                <span class="text-h6">Лог на Viber</span>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div class="log-section">
                  <div v-if="!viberLog || !viberLog.length">
                    Няма данни
                  </div>
                  <div
                    v-for="v in viberLog"
                    :key="v.id"
                    class="log-section-section-item"
                    :class="`${!v.error ? 'success' : 'error'}--text`"
                  >
                    <span>[{{ v.createdAt | formatDateTime }}]</span>
                    <span> [{{ v.event }}] </span>
                    <span v-if="v.event === 'send_message'">
                      <span v-if="v.payload.type === 'text'">
                        {{ v.payload.text }}
                      </span>
                      <span v-if="v.payload.type === 'rich_media'">
                        Съобщение за седмичен график
                      </span>
                    </span>
                    <span v-if="v.error"> {{ v.error }}</span>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="dialog = false">
          Затвори
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {},
  props: {
    machineName: String,
    machine: Object,
    viberLog: Array,
    notifications: Array,
    assignmentNotificationStatus: Number,
    hasAssignments: Boolean,
    loading: Boolean
  },
  data() {
    return {
      dialog: false,
      panel: false,
      viberLogLoaded: false
    };
  },
  computed: {
    staffId() {
      return this.notifications.length && this.notifications[0].staffId;
    },
    isConfirmed() {
      return this.notifications.length && this.notifications[0].isConfirmed;
    }
  },
  methods: {
    show() {
      this.dialog = true;
    },
    closeModal() {
      this.dialog = false;
    },
    handleClickSend() {
      this.$emit("click:send");
    },
    handleExpand() {
      if (!this.viberLogLoaded) {
        this.$emit("expand:viber");
        this.viberLogLoaded = true;
      }
    }
  }
};
</script>

<style lang="sass" scoped>
.assignment-form-container
  min-height: 400px
.assignment-item-title
  flex: 1
  word-break: normal

.v-expansion-panel-content::v-deep .v-expansion-panel-content__wrap, .v-expansion-panel-header
  padding: 0 !important
</style>
