<template>
  <v-dialog v-model="dialog" max-width="600px" :persistent="loading">
    <v-card v-if="item" :disabled="loading" :loading="loading">
      <v-card-title
        class="white--text"
        :class="form.type === 'assignment' ? 'primary' : 'warning'"
      >
        <span class="assignment-item-title text-h5">
          Редактиране | {{ item.machineInfo.fullName }} |
          {{ item.date | formatDate }}
        </span>
        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <span>
              <v-btn dark icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </span>
          </template>

          <v-list>
            <v-list-item @click="handleDelete">
              <v-list-item-icon
                ><v-icon color="error">mdi-trash-can</v-icon></v-list-item-icon
              >
              <v-list-item-title class="error--text">{{
                $t("BUTTON.DELETE")
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-card-title>
      <v-card-text>
        <v-container class="assignment-form-container">
          <AssignmentTableItemForm :key="formKey" :form="form" edit />
          <div
            v-show="_.size(errors) > 0"
            role="alert"
            class="alert alert-danger"
          >
            <div class="alert-text" v-for="(error, i) in errors" :key="i">
              {{ error }}
            </div>
          </div>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn color="error" text @click="dialog = false">
          Затвори
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleUpdate">
          Обнови
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  UPDATE_ASSIGNMENT,
  DELETE_ASSIGNMENT
} from "@/modules/assignments/store/assignment.module";
import AssignmentTableItemForm from "./AssignmentTableItemForm";

export default {
  components: {
    AssignmentTableItemForm
  },
  props: {
    week: String,
    year: String
  },
  data() {
    return {
      item: null,
      dialog: false,
      form: {
        assignorId: null,
        locationId: null,
        type: "assignment",
        startTime: "08:00:00",
        endTime: null,
        isFullDay: true,
        description: null,
        machine: null,
        date: null
      },
      isConfirmed: false,
      errors: {},
      formKey: 0,
      dirty: false,
      loading: false
    };
  },
  computed: {},
  methods: {
    show(item) {
      this.item = item;
      this.form.machine = this.item.machine;
      this.form.date = this.item.date;
      this.form.assignorId = this.item.assignorId;
      this.form.locationId = this.item.locationId;
      this.form.type = this.item.type;
      this.form.startTime = this.item.startTime;
      this.form.endTime = this.item.endTime;
      this.form.isFullDay = this.item.isFullDay;
      this.form.description = this.item.description;
      this.isConfirmed = this.item.isConfirmed;
      this.isSend = this.item.isSend;
      this.dialog = true;
      this.formKey++;
      this.$watch("form", {
        handler() {
          this.dirty = true;
        },
        deep: true
      });
    },
    closeModal() {
      this.dialog = false;
    },
    handleUpdate() {
      if (!this.dirty) {
        this.closeModal();
        return;
      }
      this.errors = {};
      this.$store
        .dispatch(UPDATE_ASSIGNMENT, {
          id: this.item.id,
          payload: this.form
        })
        .then(data => {
          this.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message
          });

          this.$emit("update", data.data);
          this.closeModal();
        })
        .catch(response => {
          this.errors = this.$errors.format(response.data.errors || {});

          this.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message
          });
        });
    },
    handleDelete() {
      let message = this.$t("BUTTON.PLEASE_CONFIRM");
      this.$dialog
        .confirm(message)
        .then(() => {
          this.closeModal();

          this.$store
            .dispatch(DELETE_ASSIGNMENT, this.item.id)
            .then(data => {
              this.$notify({
                group: "notify",
                type: "success",
                title: "<i class='flaticon2-checkmark'></i> Success",
                text: data.message
              });
              this.$emit("delete", this.item);
              this.closeModal();
            })
            .catch(response => {
              this.$notify({
                group: "notify",
                type: "error",
                title: this.$t("ALERTS.ERROR"),
                text: response.data.message
              });
            });
        })
        .catch(() => {});
    }
  }
};
</script>

<style lang="sass" scoped>
.assignment-form-container
  min-height: 400px
.assignment-item-title
  flex: 1
  word-break: normal
</style>
